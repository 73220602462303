import React from "react";
import MegaFooter from "./MegaFooter";

const Footer = () => {
  return (
    <>
      <footer className="footer__wrap">
        <div className="main__container">
          <MegaFooter />
        </div>
      </footer>
    </>
  );
};

export default Footer;
