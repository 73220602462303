// import { BrowserRouter, Route, Routes } from "react-router-dom";
// import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import Router from "./router";

function App() {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty("--vh", `${vh}px`);
  // function showTooltip(evt) {
  //   let text = evt.target.attr("data-location");
  //   let tooltip = document.getElementById("tooltip");
  //   tooltip.innerHTML = text;
  //   tooltip.style.opacity = "1";
  //   tooltip.style.left = evt.pageX + 10 + "px";
  //   tooltip.style.top = evt.pageY + 10 + "px";
  // }
  // function hideTooltip() {
  //   var tooltip = document.getElementById("tooltip");
  //   tooltip.style.opacity = "0";
  // }

  return (
    <>
      <div className="main">
        <Header />
        <Router />
        <Footer />
      </div>
    </>
  );
}

export default App;
