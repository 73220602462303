import React from "react";
import { IMAGE_BASE_URL, IMAGE_VERSION } from "../../../Common/constants";
import {
  rb_emailAddress,
  rb_phoneNumber,
  siteMapArr,
  staticPageArr,
} from "../../../Common/config";
import SocialMedia from "../../../ReusableComponents/SocialMedia";

const MegaFooter = () => {
  return (
    <>
      <section className="sitemap__wrap">
        <div className="main__container">
          <div className="sitemap__grid">
            <div className="sitemap__grid-item">
              <div className="brand__logo">
                <a href="/">
                  <img
                    src={`${IMAGE_BASE_URL}/rb-dark.svg?v=${IMAGE_VERSION}`}
                    alt="raj-builder-logo"
                    loading="lazy"
                  />
                </a>
              </div>
              <div className="brand__address">
                <span>
                  Raj Builders - Elevating Dreams & Building Futures Since 2010
                </span>
              </div>
            </div>
            <div className="sitemap__grid-item">
              <div className="sitemap__list">
                <ul>
                  {siteMapArr.map((item, index) => (
                    <li key={index}>
                      <a
                        className={`${item.extraCls ? item.extraCls : ""}`}
                        href={`${item.url}`}
                        target={item.target}
                      >
                        <span>{item.lbl}</span>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="sitemap__grid-item">
              <h3 className="sitemap__heading">
                <span>Contact Us</span>
              </h3>
              <div className="sitemap__list">
                <ul>
                  <li>
                    <a href={`mailto:${rb_emailAddress}`}>{rb_emailAddress}</a>
                  </li>
                  <li>
                    <a href={`tel:${rb_phoneNumber}`}>{rb_phoneNumber}</a>
                  </li>
                </ul>
              </div>
              <SocialMedia />
            </div>
          </div>
          <div className="quicklink sitemap__list">
            <ul>
              {staticPageArr.map((item, index) => (
                <li key={index}>
                  <a
                    className={`  ${item.extraCls ? item.extraCls : ""}`}
                    href={`${item.url}`}
                    target={item.target}
                  >
                    <span>{item.lbl}</span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default MegaFooter;
