import React from "react";
import { menu } from "../../Common/config";

const NavMenu = ({ isMenuItemsHide }) => {
  return (
    <>
      <nav className="navbar">
        {!isMenuItemsHide && (
          <ul>
            {menu.map((item, index) => (
              <li key={index}>
                <a
                  className={`  ${item.extraCls ? item.extraCls : ""}`}
                  href={`${item.url}`}
                  target={item.target}
                >
                  <span>{item.lbl}</span>
                </a>
              </li>
            ))}
          </ul>
        )}
      </nav>
    </>
  );
};

export default NavMenu;
