export const INVALID_WORD_TOAST_TIMEOUT = 2000;
export const ERROR_TOAST_TIMEOUT = 2000;

/* Versions */
export const IMAGE_VERSION = process.env.REACT_APP_IMAGE_VERSION;
export const JSON_VERSION = process.env.REACT_APP_JSON_VERSION;

/* Paths */
export const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_NAME;

/* Contact Us */
export const errorMessages = {
  contactType: { required: "Required" },
  fullName: { required: "Required" },
  emailID: { email: "Invalid email", required: "Required" },
  countryCode: { required: "Required" },
  phoneNumber: {
    min: "Invalid Phone Number",
    max: "Invalid Phone Number",
    required: "Required",
  },
  pickedDate: { required: "Required" },
  pickedTime: {
    regex: "Invalid Time",
    required: "Required",
  },
  property: { required: "Required" },
  message: { required: "Required" },
};

export const SUCCESS_MESSAGE = "Thank you for contacting us.";

// export const SUBMIT_API = "https://api.pageclip.co/data/Contact";
export const SUBMIT_API = "/submit.php";

export const SUBMIT_API_KEY =
  "YXBpX2hXMnNmWVVaQTdUQTUyZzQwQmJHQlcweHJ1ZG1uUTh6";

/* Animations */
export const ANIMATION_BOUNCE = "animi__pulse";
export const ANIMATION_SHAKE = "animi__headshake";
export const ANIMATION_FLIP_IN = "animi__flipIn";
export const ANIMATION_FLIP_OUT = "animi__flipOut";

export const DESKTOP = "Desktop";
export const TABLET = "Tablet";
export const MOBILE = "Mobile";

export const LANDSCAPE = "Landscape";
export const PORTRAIT = "Portrait";

export const ONE_SECOND = 1000;
export const ONE_MINUTE = ONE_SECOND * 60;
export const ONE_HOUR = ONE_MINUTE * 60;
export const ONE_DAY = ONE_HOUR * 24;

const CHANGE_MEMBERSHIP_TYPE = "CHANGE_MEMBERSHIP_TYPE";
const CHANGE_PERSON_NAME = "CHANGE_PERSON_NAME";
const CHANGE_PERSON_MOBILE = "CHANGE_PERSON_MOBILE";
const CHANGE_PERSON_DOB = "CHANGE_PERSON_DOB";
const CHANGE_PERSON_GENDER = "CHANGE_PERSON_GENDER";
const CHANGE_PERSON_PINCODE = "CHANGE_PERSON_PINCODE";
const CHANGE_TAB = "CHANGE_TAB";
const CHANGE_NAME = "CHANGE_NAME";
const CHANGE_COMPANY_NAME = "CHANGE_COMPANY_NAME";
const CHANGE_EMAIL = "CHANGE_EMAIL";
const GIFT_STORAGE = "GIFT_STORAGE";
const USERS_TO_BE_GIFTED = "USERS_TO_BE_GIFTED";

const CHANGE_MOBILE_NO = "CHANGE_MOBILE_NO";
const COMPLETED_STEPS = "COMPLETED_STEPS";
const RESET_REDUCER = "RESET_REDUCER";

const membershipTypes = [
  { label: "Gold", value: "gold", price: 1100 },
  { label: "Diamond", value: "diamond", price: 2100 },
  { label: "Platinum", value: "platinum", price: 4100 },
];

const VALID_GENDERS = ["male", "female", "other", ""];
const VALID_MEMBERSHIP_TYPES = ["gold", "platinum", "diamond"];

export {
  CHANGE_TAB,
  CHANGE_NAME,
  GIFT_STORAGE,
  CHANGE_EMAIL,
  VALID_GENDERS,
  RESET_REDUCER,
  membershipTypes,
  COMPLETED_STEPS,
  CHANGE_MOBILE_NO,
  CHANGE_PERSON_DOB,
  USERS_TO_BE_GIFTED,
  CHANGE_PERSON_NAME,
  CHANGE_COMPANY_NAME,
  CHANGE_PERSON_GENDER,
  CHANGE_PERSON_MOBILE,
  CHANGE_PERSON_PINCODE,
  CHANGE_MEMBERSHIP_TYPE,
  VALID_MEMBERSHIP_TYPES,
};
