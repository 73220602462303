import React, { Suspense, lazy } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  useRouteError,
} from "react-router-dom";
import { ErrorToastProvider, LoaderProvider } from "../context";
import { PageLoader } from "../ReusableComponents";

// import ProtectedRoute from "./ProtectedRoute";
// import routes from "./paths";

const App = lazy(() => import(/* webpackChunkName: 'app' */ "../views/app"));
const Home = lazy(() => import(/* webpackChunkName: 'home' */ "../views/home"));
const ContactUs = lazy(() =>
  import(/* webpackChunkName: 'contact-us' */ "../views/contact-us")
);
const OurWork = lazy(() =>
  import(/* webpackChunkName: 'our-work' */ "../views/our-work")
);
const BuildingDetail = lazy(() =>
  import(/* webpackChunkName: 'building-detail' */ "../views/building-detail")
);

const router = createBrowserRouter(
  [
    {
      element: <App />,
      path: "/",
      errorElement: <ErrorBoundary />,
      children: [
        {
          element: <Home />,
          index: true,
          errorElement: <ErrorBoundary />,
        },
      ],
    },
    {
      element: <ContactUs />,
      path: "/contact-us",
      errorElement: <ErrorBoundary />,
      index: true,
    },
    {
      path: "/our-work",
      errorElement: <ErrorBoundary />,
      children: [
        {
          element: <OurWork />,
          index: true,
        },
        {
          element: <BuildingDetail />,
          path: ":slug",
        },
      ],
    },
  ]
  // { basename: "/" }
);
function ErrorBoundary() {
  let error = useRouteError();
  console.error(error);
  // Uncaught ReferenceError: path is not defined
  return <>error page come here...</>;
}

function RouteConfig() {
  return (
    <Suspense
      fallback={
        <div className="master__wrap">
          <PageLoader />
        </div>
      }
    >
      <LoaderProvider>
        <ErrorToastProvider>
          <RouterProvider router={router} />
        </ErrorToastProvider>
      </LoaderProvider>
    </Suspense>
  );
}

export default RouteConfig;
