export const rb_officeddress =
  "Marol, Andheri east, Mumbai, Maharashtra - 400059 (main office)";
export const rb_emailAddress = "rajbuilders@gmail.com";
export const rb_phoneNumber = "+919845756430";

// Main Navbar
export const menu = [
  {
    lbl: "About",
    url: "",
  },
  {
    lbl: "Projects",
    url: "/",
    // url: "/our-work",
  },
  {
    lbl: "Redevelop",
    url: "",
  },
  {
    lbl: "Contact Us",
    url: "/contact-us",
    extraCls: "btn btn__primary btn__primary--outline",
  },
];

// Social media URL
export const socialArr = [
  {
    iconName: "facebook",
    link: "/",
    target: "_blank",
  },
  {
    iconName: "linkedin",
    link: "/",
    target: "_blank",
  },
  {
    iconName: "instagram",
    link: "https://www.instagram.com/rajbuilders_/",
    target: "_blank",
  },
];

// Sitemap URL
export const siteMapArr = [
  {
    lbl: "About",
    url: "/",
  },
  {
    lbl: "Projects",
    url: "/",
    // url: "/our-work",
  },
  {
    lbl: "Redevelop",
    url: "/",
  },
  {
    lbl: "Contact Us",
    url: "/contact-us",
  },
  {
    lbl: "Residental",
    url: "/",
  },
  {
    lbl: "Commercial",
    url: "/",
  },
  {
    lbl: "Subscribe to our newsletter",
    url: "/",
    extraCls: "btn btn__white btn__white--outline",
  },
];

// Footer Static Pages
export const staticPageArr = [
  {
    lbl: "Privacy policy",
    url: "/",
  },
  {
    lbl: "Terms & conditions",
    url: "/",
  },
  {
    lbl: "Help & support",
  },
];

export const contactDtlArr = [
  {
    lbl: "Address",
    value: rb_officeddress,
  },
  {
    lbl: "Email ID",
    value: rb_emailAddress,
    link: `mailto:${rb_emailAddress}`,
  },
  {
    lbl: "Phone Number",
    value: rb_phoneNumber,
    link: `tel:${rb_phoneNumber}`,
  },
];

export const contactRadioArr = [
  {
    id: "customer",
    lbl: "Customer",
  },
  {
    id: "investor",
    lbl: "Investor",
  },
  {
    id: "redeveloper",
    lbl: "Re-Developer",
  },
];
export const propertyArr = ["Jamali", "Nakul Raj"];
