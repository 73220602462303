import React, { useState } from "react";
import { IMAGE_BASE_URL, IMAGE_VERSION } from "../../Common/constants";
import Navbar from "../Navbar";
import { isMobile } from "../../Common/utils";
const Header = () => {
  const [isHamburgerActive, setIsHamburgerActive] = useState(false);
  const [isHamburgerMenuActive, setIsHamburgerMenuActive] = useState(true);

  // const location = useLocation();
  function menuToggle() {
    setIsHamburgerActive((prev) => !prev);
    setIsHamburgerMenuActive((prev) => !prev);
  }

  return (
    <>
      <header className="header__wrap">
        <div className="main__container">
          <div className="header__container">
            <div className="header__logo">
              <a href="/">
                <img
                  src={`${IMAGE_BASE_URL}/rb-dark.svg?v=${IMAGE_VERSION}`}
                  alt="raj-builder-logo"
                  loading="lazy"
                />
              </a>
            </div>
            <div
              className={`header__menu ${isHamburgerActive ? "active" : ""}`}
              onClick={menuToggle}
            >
              {isMobile() ? (
                isHamburgerMenuActive ? (
                  ""
                ) : (
                  <>
                    <div className="header__logo">
                      <a href="/">
                        <img
                          src={`${IMAGE_BASE_URL}/rb-dark.svg?v=${IMAGE_VERSION}`}
                          alt="raj-builder-logo"
                          loading="lazy"
                        />
                      </a>
                    </div>
                    <Navbar />
                  </>
                )
              ) : (
                <Navbar />
              )}
            </div>
            {isMobile() && (
              <div className={`header__hamburger `} onClick={menuToggle}>
                <div className="header__hamburger-icon">
                  {!isHamburgerActive ? (
                    <i className="rb-hamburger"></i>
                  ) : (
                    <i className="rb-close"></i>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
